<template>
  <div>
    <a-modal
      :visible.sync="show"
      title="操作记录"
      destroyOnClose
      width="900px"
      @cancel="$emit('update:show', false)"
      :footer="null"
    >
      <base-table class="mb-4"
        :columnsData="columns"
        :customHeight="400"
        :tableData="tableData"
        :getListFunc="initData"
      >
      </base-table>
    </a-modal>
  </div>
</template>

<script>
import { getAllOrderOptLog } from "@/api/customer"

export default {
  components: {
    
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      columns: [
        {
          title: "操作时间",
          dataIndex: "add_time",
          align: "center",
          width: 180,
        },
        {
          title: "操作人",
          dataIndex: "operator",
          align: "center",
          width: 100,
        },
        {
          title: "内容",
          dataIndex: "content",
          align: "left",
          // width: 550,
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let res = await getAllOrderOptLog({ order_no: this.orderId })
      if(res.code == 0){
        this.tableData = res.data.list
      }
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>