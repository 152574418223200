<template>
  <div>
    <a-modal
      :visible.sync="show"
      title="流水记录"
      destroyOnClose
      width="900px"
      @cancel="$emit('update:show', false)"
      :footer="null"
    >
      <base-table class="mb-4"
        :columnsData="columns"
        :customHeight="300"
        :tableData="tableData"
        :getListFunc="initData"
      >
        <template #flow_type="{record}">
          <div>{{record.flow_type}}</div>
          <template v-if="record.refund_status">
            <a-tooltip v-if="record.refund_tip" placement="topLeft" :title="record['refund_tip']">
              <span style="color:#999;cursor:pointer;"
                >{{ formatRefundFlowStatus(record.refund_status) }}</span>
            </a-tooltip>
            <span v-else style="color:#999;"
                >{{ formatRefundFlowStatus(record.refund_status) }}</span>
          </template>
        </template>
        <template #artificial_pay_flow_no="{record}">
          <a-button v-if="record.attached_images" class="p-0" type="link" 
              @click="handlerPreview(record.attached_images)"
              >{{record.artificial_pay_flow_no || "——"}}</a-button>
          <template v-else>{{record.artificial_pay_flow_no}}</template>
        </template>
      </base-table>
    </a-modal>

    <a-modal class="preview-class"
             title="流水凭证"
             width="500px"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <div style="height:600px;overflow: auto;">
        <a v-for="(item,i) in previewImgs"
          :key="i" :href="item" target="_blank">
          <img style="width: 100%" :src="item" />
        </a>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getAllOrderFlowLog } from "@/api/customer"

export default {
  components: {
    
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      columns: [
        {
          title: "流水号",
          dataIndex: "flow_no",
          align: "center",
          // width: 200,
        },
        {
          title: "第三方流水号",
          dataIndex: "artificial_pay_flow_no",
          align: "center",
          // width: 200,
          slots: { customRender: "artificial_pay_flow_no" },
        },
        {
          title: "时间",
          dataIndex: "add_time",
          align: "center",
          // width: 130,
        },
        {
          title: "类型/状态",
          dataIndex: "flow_type",
          align: "center",
          width: 110,
          slots: { customRender: "flow_type" },
        },
        {
          title: "方式",
          dataIndex: "pay_mode",
          align: "center",
          width: 80,
        },
        {
          title: "金额",
          dataIndex: "amount",
          align: "center",
          width: 80,
        },
        {
          title: "财务账户",
          dataIndex: "financial_account",
          align: "center",
          width: 100,
        },
        {
          title: "操作人",
          dataIndex: "operator",
          align: "center",
          width: 80,
        },
      ],
      tableData: [],

      previewImgs: [],
      previewVisible: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let res = await getAllOrderFlowLog({ order_no: this.orderId })
      if(res.code == 0){
        this.tableData = res.data.list.map(flow=>{
          if(flow.refund_status == 3){
            flow.refund_tip = flow['refundsucc_time'] + ' 退款完成'
          }
          if(flow.refund_status == 4){
            flow.refund_tip = flow['refunderr_time'] + ' 退款失败' 
              + (flow['refunderr_reason'] ? ("，原因："+flow['refunderr_reason']) : '')
          }
          return flow
        })
      }
    },

    handlerPreview(imgs){
      this.previewImgs = imgs.split(",")
      this.previewVisible = true
    },

    formatRefundFlowStatus(value){
      if(value == 1) return "待退款"
      if(value == 2) return "退款中"
      if(value == 3) return "已完成"
      if(value == 4) return "退款失败"
      return "未知"
    }

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>